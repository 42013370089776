import * as fcl from "@onflow/fcl";
// import { init as wcInit } from "@onflow/fcl-wc";

// // Initialize WalletConnect with FCL
// async function setupWalletConnect() {
//   const { FclWcServicePlugin } = await wcInit({
//     projectId: "b245341709c9b1a6ba926c06a0b285c0",  // You need to replace this with your actual project ID
//     metadata: {
//       name: "Driverz Inc.",
//       description: "Auto Racing Collectibles",
//       url: "https://driverz.world",
//     },
//     includeBaseWC: true,
//   });

//   // Add the WalletConnect plugin to FCL's plugin registry
//   fcl.pluginRegistry.add(FclWcServicePlugin);
// }

fcl.config({
  "discovery.wallet": process.env.REACT_APP_DAPPER_WALLET_DISCOVERY,
  "discovery.wallet.method": process.env.REACT_APP_DAPPER_WALLET_DISCOVERY_METHOD,
  "accessNode.api": process.env.REACT_APP_FLOW_ACCESS_NODE,
  "app.detail.title": process.env.REACT_APP_APP_NAME,
  "app.detail.icon": process.env.REACT_APP_APP_ICON,
  "discovery.authn.endpoint": "https://fcl-discovery.onflow.org/api/authn",
  "discovery.authn.include": [
    "0xead892083b3e2c6c", // Dapper Wallet
  ],
});

// Call to setup WalletConnect
// setupWalletConnect();

console.log("Access Node:", process.env.REACT_APP_FLOW_ACCESS_NODE);