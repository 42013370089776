export const getAllTresors = `
import VroomTokenRepositoryX from 0xfdfe39186c5e3b90 // Replace with actual contract address

access(all) fun main(): [VroomTokenRepositoryX.TresorDetails] {
    // Prepare an array to hold the details of all tresors
    let allTresorDetails: [VroomTokenRepositoryX.TresorDetails] = []

    // Get the public capability to the VroomTokenRepository and borrow it
    let repositoryRef = getAccount(0xf887ece39166906e)
        .capabilities.borrow<&VroomTokenRepositoryX.Repository>(VroomTokenRepositoryX.RepositoryPublicPath)
        ?? panic("Could not borrow the repository reference")

    // Retrieve all Tresor IDs
    let tresorIDs = repositoryRef.getTresorIDs()

    // Iterate over each Tresor ID and borrow each Tresor to get its details
    for tresorID in tresorIDs {
        let tresorRef = repositoryRef.borrowTresor(tresorResourceID: tresorID)
            ?? panic("Could not borrow the tresor.")

        // Add the details of each tresor to the array
        allTresorDetails.append(tresorRef.getDetails())
    }

    return allTresorDetails
}
`