export const purchaseVroom = `
import FungibleToken from 0xf233dcee88fe0abe
import FlowToken from 0x1654653399040a61
import VroomTokenRepositoryX from 0xfdfe39186c5e3b90 // Replace with actual contract address
import VroomToken from 0xf887ece39166906e      // Replace with actual contract address
import RaffleTickets from 0x08149b33780fe3b1
import NonFungibleToken from 0x1d7e57aa55817448

transaction(tresorId: UInt64, repositoryAddress: Address, expectedPrice: UFix64) {
    
    let repository: &VroomTokenRepositoryX.Repository
    let tresorRef: &{VroomTokenRepositoryX.TresorPublic}
    let mainFlowVault: auth(FungibleToken.Withdraw) &FlowToken.Vault
    let balanceBeforeTransfer: UFix64
    let paymentVault: @{FungibleToken.Vault}    
    let tokenVault: &VroomToken.Vault

    prepare(acct: auth(Storage, Capabilities) &Account) {

        // Set up the raffle ticket
        if acct.storage.borrow<&RaffleTickets.Collection>(from: RaffleTickets.CollectionStoragePath) == nil {
            let collection <- RaffleTickets.createEmptyCollection(nftType: Type<@RaffleTickets.NFT>())
            acct.storage.save(<-collection, to: RaffleTickets.CollectionStoragePath)

            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(RaffleTickets.CollectionStoragePath),
                at: RaffleTickets.CollectionPublicPath    
            )
        }
        
        // Borrow a reference to the repository where the tresor is located
        self.repository = getAccount(repositoryAddress)
            .capabilities.borrow<&VroomTokenRepositoryX.Repository>(VroomTokenRepositoryX.RepositoryPublicPath)
            ?? panic("Could not borrow the repository reference.")

        // Borrow a reference to the tresor where the tokens are stored
        self.tresorRef = self.repository.borrowTresor(tresorResourceID: tresorId)
            ?? panic("No tresor with ID in Repository.")
        let price = self.tresorRef.getDetails().price

        assert(expectedPrice == price, message: "Sale price not expected value")

        //Set up flow receiving for the seller
        


        // Withdraw Flow amount from buyer
        self.mainFlowVault = acct.storage.borrow<auth(FungibleToken.Withdraw) &FlowToken.Vault> (from: /storage/flowTokenVault)
            ?? panic("Cannot grab access to buyer's Flow Vault")
        self.balanceBeforeTransfer = self.mainFlowVault.balance
        self.paymentVault <- self.mainFlowVault.withdraw(amount: price)

        // Initialize the buyer's VroomToken vault if it doesn't exist
        if acct.storage.borrow<&VroomToken.Vault>(from: VroomToken.VaultStoragePath) == nil {

            acct.storage.save(<-VroomToken.createEmptyVault(vaultType: Type<@VroomToken.Vault>()), to: VroomToken.VaultStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(VroomToken.VaultStoragePath),
                at: VroomToken.VaultReceiverPath
            )
        }

        self.tokenVault = acct.storage.borrow<&VroomToken.Vault>(from: VroomToken.VaultStoragePath)!
    }

    execute {
        // Step 6: Transfer Tit Tokens from the Tresor to the buyer's Vault
        self.tresorRef.transferTokens(buyerVaultRef: self.tokenVault)

        // Step 7: Deposit the payment into the seller's FlowToken Vault
        let sellerAddress = self.tresorRef.getDetails().seller
        let sellerAccount = getAccount(sellerAddress)
        let sellerFlowReceiver = sellerAccount.capabilities.borrow<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)
            ?? panic("Could not borrow the seller's FlowToken receiver.")
        sellerFlowReceiver.deposit(from: <-self.paymentVault)

        // Step 8: Remove the Tresor from the repository to finalize the purchase
        self.repository.removeTresor(signer: self.tresorRef.getDetails().seller, tresorId: tresorId)


    }
}
`
