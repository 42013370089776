export const getRaffleDetails = `
import RaffleTickets from 0xf887ece39166906e
import NonFungibleToken from 0x1d7e57aa55817448


access(all) fun main(accountAddress: Address): [UInt64] {
    // Get the account from the provided address
    let account = getAccount(accountAddress)

    // Borrow a reference to the account's RaffleTickets collection
    let collectionRef = account.capabilities.borrow<&{NonFungibleToken.CollectionPublic}>(RaffleTickets.CollectionPublicPath)
        ?? panic("Could not borrow the reference to the RaffleTickets collection")

    // Get all the NFT IDs from the collection
    let nftIDs = collectionRef.getIDs()

    // Return the array of NFT IDs
    return nftIDs
}


`