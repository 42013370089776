export const purchaseCC1 = `

import FungibleToken from 0xf233dcee88fe0abe
import NonFungibleToken from 0x1d7e57aa55817448
import TitPalaceStorefrontXxX from 0xfdfe39186c5e3b90
import RaffleTickets from 0xf887ece39166906e
import CarClub from 0xf887ece39166906e
import VroomToken from 0xf887ece39166906e

transaction(storefrontAddress: Address, listingResourceID: UInt64, expectedPrice: UFix64) {
    let paymentVault: @{FungibleToken.Vault}
    let nftCollections: {Type: &{NonFungibleToken.CollectionPublic}} // Dictionary to hold multiple NFT collections
    let storefront: &TitPalaceStorefrontXxX.Storefront
    let listingRef: &{TitPalaceStorefrontXxX.ListingPublic}
    let tokenVault: &{FungibleToken.Receiver}

    prepare(acct: auth(Storage, Capabilities) &Account) {
        self.nftCollections = {}

        // Borrow the storefront reference from the specified address
        self.storefront = getAccount(storefrontAddress)
            .capabilities.borrow<&TitPalaceStorefrontXxX.Storefront>(TitPalaceStorefrontXxX.StorefrontPublicPath)
            ?? panic("Could not borrow the storefront resource.")

        // Borrow a reference to the specific listing
        self.listingRef = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No listing found with the given ID.")

        // Get the sale price from the listing and ensure it matches the expected price
        let salePrice = self.listingRef.getDetails().salePrice
        assert(expectedPrice == salePrice, message: "The sale price does not match the expected price.")

        // Withdraw the sale price from the buyer's FungibleToken vault
        let fungibleTokenVaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: /storage/flowTokenVault)
            ?? panic("Could not borrow the buyer's Flow Token vault reference.")
        self.paymentVault <- fungibleTokenVaultRef.withdraw(amount: salePrice)

        // Setup or borrow Raffle Tickets NFT collection
        if acct.storage.borrow<&RaffleTickets.Collection>(from: RaffleTickets.CollectionStoragePath) == nil {
            let collection <- RaffleTickets.createEmptyCollection(nftType: Type<@RaffleTickets.NFT>())
            acct.storage.save(<-collection, to: RaffleTickets.CollectionStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(RaffleTickets.CollectionStoragePath),
                at: RaffleTickets.CollectionPublicPath
            )
        }
        self.nftCollections[Type<@RaffleTickets.NFT>()] = acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: RaffleTickets.CollectionStoragePath)
            ?? panic("Could not borrow Raffle Tickets collection.")

        // Setup or borrow Car Club NFT collection
        if acct.storage.borrow<&CarClub.Collection>(from: CarClub.CollectionStoragePath) == nil {
            let collection <- CarClub.createEmptyCollection(nftType: Type<@CarClub.NFT>())
            acct.storage.save(<-collection, to: CarClub.CollectionStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(CarClub.CollectionStoragePath),
                at: CarClub.CollectionPublicPath
            )
        }
        self.nftCollections[Type<@CarClub.NFT>()] = acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: CarClub.CollectionStoragePath)
            ?? panic("Could not borrow Car Club collection.")

        // Ensure the buyer's VroomToken vault exists or set it up
        if acct.storage.borrow<&VroomToken.Vault>(from: VroomToken.VaultStoragePath) == nil {
            let vroomVault <- VroomToken.createEmptyVault(vaultType: Type<@VroomToken.Vault>())
            acct.storage.save(<-vroomVault, to: VroomToken.VaultStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(VroomToken.VaultStoragePath),
                at: VroomToken.VaultReceiverPath
            )
        }
        self.tokenVault = acct.storage.borrow<&{FungibleToken.Receiver}>(from: VroomToken.VaultStoragePath)
            ?? panic("Could not borrow the buyer's VroomToken vault.")
    }

    execute {
        // Call the purchase method on the listing, passing the appropriate NFT collections
        self.listingRef.purchase(
            payment: <-self.paymentVault,
            nftCollections: self.nftCollections,
            ftReceiver: self.tokenVault
        )

        // Remove the listing after successful purchase
        self.storefront.removeListing(listingResourceID: listingResourceID)
    }
}

`