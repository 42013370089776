import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import './admin.css';
import { getListingInsDetails } from "../../Cadence/insurance/getListingInsuranceDetails";
import { purchaseInsurance } from "../../Cadence/insurance/purchaseInsurance"; // done
import { transferVroom } from "../../Cadence/Transactions/transferVroom";
import { getCarClubListingDetails } from "../../Cadence/Vroom/scripts/getCarClubDetails";
import { purchaseCarClubListing } from "../../Cadence/Vroom/transactions/purchaseCarClubListing";
import singleCC from '../../assets/Images/singleCCSale.png';
import packCC from '../../assets/Images/packCCSale.png';
import { db } from '../../firebaseTurbo';
import { db1 } from '../../firebaseDRL';
//import { db3 } from '../../firebaseSilent';
import { collection, getDocs, orderBy, updateDoc, arrayUnion, where, limit, query, deleteDoc, addDoc, getDoc, doc} from "firebase/firestore";
const videoUrl = "https://gateway.pinata.cloud/ipfs/Qmb2FFHiULyPYcX2DrvHxa3ME2dQiNbNHAwySqVom5kqt2/%285425%29.mp4";

export default function Admin() {
    const [password, setPassword] = useState("");
    const [authenticated, setAuthenticated] = useState(false);
    const [nullListings, setNullListings] = useState([]);
    const [packListings, setPackListings] = useState([]);
    const [receiver, setReceiver] = useState([]);
    const [amount, setAmount] = useState("");
    const [receiverValid, setReceiverValid] = useState(false);
    const [amountValid, setAmountValid] = useState(false);
    const [games, setGames] = useState({ standard: [], pro: [], elite: []});
    const [userWagers, setUserWagers] = useState([]);
    const [flowPriceUSD, setFlowPriceUSD] = useState(null);
    const [vroomPriceFlow, setVroomPriceFlow] = useState(0.009090909);
    const [listings3050, setListings3050] = useState([]);
    const [listings9000, setListings9000] = useState([]);
    const [isSoldOut3050, setIsSoldOut3050] = useState(false);
    const [isSoldOut9000, setIsSoldOut9000] = useState(false);
    const [bid, setBid] = useState(''); // State for handling the bid input
    const [feedbackMessage, setFeedbackMessage] = useState(''); // Optional: For user feedback
    const [userHighestBid, setUserHighestBid] = useState(null); // Placeholder for user's highest bid
    const [currentHighestBid, setCurrentHighestBid] = useState('');
    const [userAddress, setUserAddress] = useState(null); 


    /*useEffect(() => {
        // Fetch the user's highest bid
        const fetchUserHighestBid = async () => {
            if (userAddress) {
                let highestBid = null;
                const q = query(collection(db1, "silent"), where("userAddress", "==", userAddress), orderBy("currentBid", "desc"));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    const bidDoc = doc.data();
                    if (!highestBid || bidDoc.currentBid > highestBid) {
                        highestBid = bidDoc.currentBid;
                    }
                });
                setUserHighestBid(highestBid); // This will be null if no bids found
            }
        };
    
        // Fetch the highest bid overall
        const fetchHighestBid = async () => {
            let highestBid = null;
            const q = query(collection(db1, "silent"), orderBy("currentBid", "desc"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                console.log(doc.data());
                const bidDoc = doc.data();
                if (!highestBid || bidDoc.currentBid > highestBid) {
                    highestBid = bidDoc.currentBid;
                }
            });
            setCurrentHighestBid(highestBid); // This will be null if no bids found
        };
    
        fetchUserHighestBid();
        fetchHighestBid();
    }, [userAddress]);*/
    

    
    useEffect(() => {
        // Subscribe to user state changes
        const unsubscriber = fcl.currentUser().subscribe((user) => {
            if (user.loggedIn) {
                setUserAddress(user.addr);
            } else {
                setUserAddress(null);
            }
        });

        // Cleanup subscription on component unmount
        return () => unsubscriber();
    }, []); // Dependency array is empty to run only on component mount

    useEffect(() => {
        console.log("User Address:", userAddress); // Log the user address to the console when it changes
    }, [userAddress]); // Re-run this effect whenever `userAddress` changes


    useEffect(() => {
        const fetchAllWagers = async () => {
            const querySnapshot = await getDocs(collection(db1, "04112024"));
            const wagers = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                // Optionally, you can format the date here if needed
                wagers.push(data);
            });
            setUserWagers(wagers);
        };

        const fetchFlowPrice = async () => {
            try {
                const response = await fetch('https://api.kraken.com/0/public/Ticker?pair=FLOWUSD');
                const data = await response.json();
                // Parse the response to find the price. The path to the price depends on Kraken's response structure
                // Assuming the price is located at: data.result.FLOWUSD.c[0]
                const price = data.result.FLOWUSD.c[0];
                setFlowPriceUSD(price);
            } catch (error) {
                console.error("Failed to fetch FLOW price from Kraken:", error);
                setFlowPriceUSD('Error fetching price');
            }
        };
    
        fetchAllWagers();
        fetchFlowPrice();
    }, []); // Removed dependencies to run once on component mount
    

    const handleBidSubmit = async (e) => {
        e.preventDefault();
    
        // Parse the new bid as a floating-point number and ensure two decimal places
        const newBid = parseFloat(bid).toFixed(2);
    
        // Ensure the new bid is greater than the current highest bid
        if (userHighestBid && parseFloat(newBid) <= parseFloat(userHighestBid)) {
            setFeedbackMessage("Your new bid must be higher than your current highest bid.");
            return; // Stop the function execution here
        }
    
        // Calculate the difference between the new bid and the current highest bid
        let bidDifference = userHighestBid ? (parseFloat(newBid) - parseFloat(userHighestBid)).toFixed(2) : newBid;
    
        try {
            // Only proceed with transfer if there's an actual difference to transfer
            if (parseFloat(bidDifference) > 0) {
                // Call transferVroom and await its completion before proceeding
                const transactionId = await handleTransferVroom(bidDifference);
    
                if (transactionId) {
                    console.log("Saving to database...");
                    // Only proceed to save the bid if the transfer is successful
                    await addDoc(collection(db1, "silent"), {
                        currentBid: newBid,
                        userAddress: userAddress,
                        transactionId: transactionId,
                        timestamp: new Date()
                    });
                    setFeedbackMessage("Bid submitted successfully!");
                    // Update the user's highest bid to reflect the new bid
                    setUserHighestBid(newBid);
                } else {
                    setFeedbackMessage("Transaction failed.");
                }
            } else {
                setFeedbackMessage("No additional amount needs to be transferred.");
            }
    
            setBid(''); // Clear the bid input after successful or failed submission
        } catch (error) {
            console.error("Error during bid submission or transfer process: ", error);
            setFeedbackMessage("Failed to submit bid.");
        }
    };
    
    
    const handleTransferVroom = async (amount) => {
        try {
            console.log(amount);


            const TXid = await fcl.mutate({
                cadence: transferVroom,
                args: (arg, t) => [arg(amount, t.UFix64), arg("0xf887ece39166906e", t.Address)],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
            console.log("Processing Transaction: ", TXid);
            return TXid;
        } catch (error) {
            console.error("Error in handleTransferVroom:", error);
            return null; // Indicate failure
        }
    };
    

    // Validate Flow address pattern
    useEffect(() => {
      const flowAddressPattern = /^0x[a-fA-F0-9]{16}$/;
      setReceiverValid(flowAddressPattern.test(receiver));
    }, [receiver]);

    useEffect(() => {
        if (authenticated) {
            fetchGames();
        }
    }, [authenticated]);

    const fetchGames = async () => {
        const gameLevels = ['standard', 'pro', 'elite'];
        let gamesData = {};
      
        for (let level of gameLevels) {
          console.log(`Fetching games for level: ${level}`); // Log the level being processed
      
          const q = query(
            collection(db, 'Games'), 
            where('gameLevel', '==', level), 
            where('processed', '==', false), 
            orderBy('createdAt'), 
            limit(1)
          );
          const querySnapshot = await getDocs(q);
      
          if (querySnapshot.empty) {
            console.log(`No games found for level: ${level}`);
            gamesData[level] = null;
          } else {
            const gameDoc = querySnapshot.docs[0];
            const gameData = gameDoc.data();
            const documentId = gameDoc.id; // Get the document ID
            console.log(`Game found for level ${level} with documentId ${documentId}:`, gameData);
            
            // Add the documentId to the gameData
            gameData.documentId = documentId;
            gamesData[level] = gameData;
          }
        }
      
        console.log("All fetched games data:", gamesData);
        setGames(gamesData);
      };
      
    

    // Validate amount and append .0 if needed
    useEffect(() => {
      const parts = amount.split(".");
      if (!isNaN(Number(amount)) && Number(amount) > 0) {
          setAmountValid(true);
          if (parts[1] && parts[1].length > 7) {
              setAmountValid(false);
          }
      } else {
          setAmountValid(false);
      }
  }, [amount]);
  

   const handleAmountChange = (e) => {
    const value = e.target.value;
    const parts = value.split(".");
 
    if (parts[1] && parts[1].length > 7) {
      return; // Prevent adding more than 7 digits after decimal
    }
 
    setAmount(value);
 };
  


    const getNullListings = async () => {
        try {
          const listings = await fcl.query({
            cadence: getListingInsDetails,
            args: (arg, t) => [
              arg("0xf887ece39166906e", t.Address)
            ],
          });
    
         /* console.log(listings);*/
          setNullListings(listings);
    
          const listingIDsWithPrice49 = listings
            .filter((listing) => listing.price === "0.10000000")
            .map((listing) => listing.listingID);
    
         /* console.log("Listing IDs with price null:", listingIDsWithPrice49);*/
    
          setNullListings(listingIDsWithPrice49);
    
        } catch (error) {};
    };

    const getListingDetails = async () => {
        try {
            const details = await fcl.query({
                cadence: getCarClubListingDetails,
                args: (arg, t) => [
                  arg("0xf887ece39166906e", t.Address)
                ],
              });
        // Assuming the data structure is directly usable and price is a string
        // Adjust the comparison to account for string comparison and ensure prices are compared correctly
        const filtered3050 = details.filter(listing => parseFloat(listing.price) === 3050.0 && !listing.purchased);
        const filtered9000 = details.filter(listing => parseFloat(listing.price) === 9000.0 && !listing.purchased);
    
              setListings3050(filtered3050);
              setListings9000(filtered9000);

                            // Print the arrays to the console
                console.log("Listings Priced at 3050.0:", filtered3050);
                console.log("Listings Priced at 9000.0:", filtered9000);
        } catch(error) {};
    }

    useEffect(() => {
        getListingDetails();
      }, []); 

        // Effect hook to update sold out status for 3050 listings
    useEffect(() => {
        setIsSoldOut3050(listings3050.length === 0 || listings3050.every(listing => listing.purchased));
    }, [listings3050]); // Dependency array includes listings3050 to re-run this effect when listings3050 changes

    // Effect hook to update sold out status for 9000 listings
    useEffect(() => {
        setIsSoldOut9000(listings9000.length === 0 || listings9000.every(listing => listing.purchased));
    }, [listings9000]); // Dependency array includes listings9000 to re-run this effect when listings9000 changes

    const purchase3050 = async () => {
        try {
            console.log("Attempting to purchase at 3050.0 vroom...");
            const listingID = listings3050[0].listingId;
            const address = listings3050[0].storefrontAddress;
            console.log("ListingID: ", listingID, "Address Storefront: ", address);
            console.log("TX ID Input: ", typeof listingID, listingID);

            
            const txid = await fcl.mutate({
                cadence: purchaseCarClubListing,
                args: (arg, t) => [
                    arg(listingID, t.UInt64),
                    arg(address, t.Address)
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
    
            console.log(`Transaction ID: ${txid}`);
            await fcl.tx(txid).onceSealed();
            console.log("Transaction sealed.");
        } catch (error) {
            console.error("Error in purchase3050:", error);
            // Additional error handling can be done here
        }
    };

    const purchase9000 = async () => {
        try {
            console.log("Attempting to purchase at 9000.0 vroom...");
            const listingID = listings9000[0].listingId;
            const address = listings9000[0].storefrontAddress;
            console.log("ListingID: ", listingID, "Address Storefront: ", address);
            console.log("TX ID Input: ", typeof listingID, listingID);

            
            const txid = await fcl.mutate({
                cadence: purchaseCarClubListing,
                args: (arg, t) => [
                    arg(listingID, t.UInt64),
                    arg(address, t.Address)
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
    
            console.log(`Transaction ID: ${txid}`);
            await fcl.tx(txid).onceSealed();
            console.log("Transaction sealed.");
        } catch (error) {
            console.error("Error in purchase9000:", error);
            // Additional error handling can be done here
        }
    };

    const getPackListings = async () => {
        try {
          const listings = await fcl.query({
            cadence: getListingInsDetails,
            args: (arg, t) => [
              arg("0xf887ece39166906e", t.Address)
            ],
          });
    
         /* console.log(listings); */
          setPackListings(listings);
    
          const listingIDsWithPrice49 = listings
            .filter((listing) => listing.price === "0.02000000")
            .map((listing) => listing.listingID);
    
         /* console.log("Listing IDs with price null:", listingIDsWithPrice49); */
    
          setPackListings(listingIDsWithPrice49);
    
        } catch (error) {};
    };

    useEffect(() => {
        getNullListings();
        getPackListings();
    }, [])

    const handleNullPurchase = async () => {
        await getNullListings();
        await purchaseNull();
    };

    const handlePackPurchase = async () => {
        await getPackListings();
        await purchasePack();
    };
    

    const purchaseNull = async () => {
      try {
          const listingID = nullListings[0];        
          console.log("TX ID Input: ", typeof listingID, listingID)
          const txid = await fcl.mutate({
              cadence: purchaseInsurance,
              args: (arg, t) => [
                arg('0x6817fd8ae667556e', t.Address),
                arg("0xf887ece39166906e", t.Address),
                arg(listingID, t.UInt64),
                arg('0.1', t.UFix64)
              ],
              proposer: fcl.currentUser,
              payer: fcl.currentUser,
              authorizations: [fcl.currentUser],
              limit: 999,
          });
          return txid;
      } catch(error) {
          console.error("Error in purchaseNull:", error);
      }
  };
    const purchasePack = async (/*matchingListing*/) => {

        try {
            // const listingID = matchingListing?.listingID?.toString();     
            // console.log(listingID);
    
            // const ID = findListingID();
            const listingID = packListings[0];        
            console.log("TX ID Input: ", typeof listingID, listingID)
    
          const txid = await fcl.mutate({
            cadence: purchaseInsurance,
            args: (arg, t) => [
              arg('0x6817fd8ae667556e', t.Address),
              arg("0xf887ece39166906e", t.Address),
              arg(listingID, t.UInt64),
              arg('0.02', t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64) //arg(price + ".0", t.UFix64)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
          });
          } catch(error) {}
    };

    const handleTransfer = async () => {
      try {
          const TXid = await fcl.mutate({
              cadence: transferVroom,
              args: (arg, t) => [
                arg(amount, t.UFix64),
                arg(receiver, t.Address)
              ],
              proposer: fcl.currentUser,
              payer: fcl.currentUser,
              authorizations: [fcl.currentUser],
              limit: 999,
          });
          return TXid;
      } catch(error) {
          console.error("Error in handleTransfer:", error);
      }
  };

  const handleDoubleTransaction = async () => {
    const nullPurchaseTxid = await purchaseNull();
    if (nullPurchaseTxid) {
        console.log("Null purchase successful. Transaction ID:", nullPurchaseTxid);
        const transferTxid = await handleTransfer();
        if (transferTxid) {
            console.log("Transfer successful. Transaction ID:", transferTxid);
        }
    }
};

function calculatePayout(amount, line) {
    let payout = 0;
    if (line > 0) {
        // Positive line, e.g., +150 means bet $100 to win $150
        payout = amount + (amount * line) / 100;
    } else if (line < 0) {
        // Negative line, e.g., -120 means bet $120 to win $100
        payout = amount + (amount * 100) / Math.abs(line);
    }
    return payout.toFixed(2); // Returns the payout as a string with two decimal places
}

const handleBurn = async () => {
    try {
        const TXid = await fcl.mutate({
            cadence: transferVroom,
            args: (arg, t) => [
              arg(amount, t.UFix64),
              arg('0x54ed16aa81e0149f', t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
        return TXid;
    } catch(error) {
        console.error("Error in handleTransfer:", error);
    }
  };

  const processGame = async (level, documentId) => {
    try {
      // Create a reference to the specific game document using both level and documentId
      console.log("Document ID: ", documentId);
      const gameRef = doc(db, 'Games', documentId);
  
      // Update the 'processed' field to true
      await updateDoc(gameRef, {
        processed: true
      });
  
      console.log(`Game for level ${level} with documentId ${documentId} has been processed.`);
    } catch (error) {
      console.error(`Error processing game for level ${level}:`, error);
    }
  };

    const checkPassword = () => {
        if (password === "Dr!verz@dm1n") {
            setAuthenticated(true);
        } else {
            alert("Incorrect password!")
        }
    };

    if (authenticated) {
        return <div className="container-admin">
            <div className="admin-1">
                Driverz Admin
            </div>
            <div className="section-divider"></div>
            <div className="admin-2">
                Admin Giveaways
            </div>
            {/*Dr!verz@dm1n
            <div className="test-button">
                <button onClick={handleNullPurchase} className="mint-button">Test Purchase</button>
            </div> 
            <div className="test-button">
                <button onClick={handlePackPurchase} className="mint-button">Test Pack Purchase</button>
            </div> */}
              <div className='vroom-transfer-box'>
                <div className='vroom-input-row'><p className='transfer-box-header'>Transfer $VROOM</p></div>
                <div className='vroom-input-row'>
                    <input
                        type="text"
                        value={receiver}
                        onChange={e => setReceiver(e.target.value)}
                        placeholder="Receiver Address"
                    />
                    {!receiverValid && <span className='invalid-feedback'>Invalid Flow address</span>}
                </div>
                <div className='vroom-input-row'>
                    <input
                        type="text"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder="Amount"
                    />
                    {!amountValid && <span className='invalid-feedback'>Invalid amount</span>}
                </div>
                <div className='vroom-input-row'>
                  <p style={{color: "red", fontSize: "0.9rem"}}>Please append '.0' to whole number values when entering the amount.</p>
                    <button onClick={handleTransfer} disabled={!receiverValid || !amountValid}>
                        Transfer $VROOM
                    </button>
                </div>
              </div>
              <div className="section-divider"></div>
            <div className="admin-2">
                Upcoming Vroom Functionality
            </div>
            <div className="balances-container">
                <div className="balance">
                    <h3>$VROOM Exchange</h3>
                    <p className="balance-amount">{vroomPriceFlow}</p>
                </div>
                <div className="balance">
                    <h3>$FLOW Exchange</h3>
                    <p className="balance-amount">{flowPriceUSD}</p>
                </div>
            </div>
            <div className="pack-row-admin">
                <div className="pack-column">
                <img src={singleCC} alt="Car Club Collectible" />
                    <h3>Car Club <br /> 3050 $VROOM </h3>
                    <h4>Car Club Collectible</h4>
                    <button
                        className='mint-button'
                        onClick={!isSoldOut3050 ? purchase3050 : undefined} // Add the onClick handler
                        disabled={isSoldOut3050}
                    >
                        {isSoldOut3050 ? 'SOLD OUT' : 'BUY'}
                    </button>
                </div>
                <div className="pack-column">
                <img src={packCC} alt="Toolbox Bundle" />
                    <h3>Toolbox Bundle <br /> 9000 $VROOM </h3>
                    <h4>4 Car Clubs</h4>
                    <button
                        className='mint-button'
                        onClick={!isSoldOut9000 ? purchase9000 : undefined} // Add the onClick handler
                        disabled={isSoldOut9000}
                    >
                        {isSoldOut9000 ? 'SOLD OUT' : 'BUY'}
                    </button>
                </div>
            </div>
            {/*<div className="section-divider"></div>
            <div className="admin-2">
                Silent Auction
            </div>
            <div className="auction-tile">
                <div className="title-label">Rare Car Club #5425</div> 
                <div className="video-container">
                    <video className="silent-auction-video" loop muted autoPlay>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                {/* Current Highest Bid 
                <div className="bid-container">
                    <p>Current highest bid: ${currentHighestBid}</p>
                    {userHighestBid && <p>Your highest bid: ${userHighestBid}</p>}
                </div>
                {/* Bid Submission Form 
                <div className="bid-container">
                    <form onSubmit={handleBidSubmit} className="bid-form">
                        <input
                            id="bidInput"
                            type="number"
                            value={bid}
                            onChange={(e) => setBid(e.target.value)}
                            placeholder="Your Bid"
                        />
                        <button className="mint-button" type="submit">PLACE BID</button>
                    </form>
                    {/*{feedbackMessage && <p>{feedbackMessage}</p>}
                </div>
        </div> */}
            <div className="section-divider"></div>
            <div className="admin-2">
                Driverz Sim Entries
            </div>
            <div className="games-container">

                {Object.entries(games).map(([level, game]) => (
                    <div key={level} style={{ flex: 1, marginRight: '10px' }}>
                    <h2>{level.charAt(0).toUpperCase() + level.slice(1)} Game</h2>
                    {game ? (
                        <div>
                        <table className="players-table">
                            <thead>
                            <tr>
                                <th>Player ID</th>
                                <th>Flow Address</th>
                            </tr>
                            </thead>
                            <tbody>
                            {game.players ? (
                                game.players.map((player, index) => (
                                <tr key={index}>
                                    <td>{player.driverId}</td>
                                    <td>{player.flowAddress}</td>
                                </tr>
                                ))
                            ) : (
                                <tr>
                                <td colSpan="2">No players data available</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <button className="mint-button"
                            onClick={() => processGame(level, game.documentId)}
                            disabled={!game.players || game.players.length < 16}
                        >
                            Process Game
                        </button>
                        </div>
                    ) : (
                        <p>Waiting for players...</p>
                    )}
                    </div>
                ))}
            </div>
            <div className="section-divider"></div>
            <div className="admin-2">
                Placed Wagers DRL (03/21/2024)
            </div>
            <div className="table-container">
                <table className="custom-table">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Selection</th>
                            <th>Line</th>
                            <th>Amount $VROOM</th>
                            <th>Time</th>
                            <th>Payout</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userWagers.map((wager, index) => (
                            <tr key={index}>
                                <td>{wager.user}</td>
                                <td>{wager.selection}</td>
                                <td>{wager.line}</td>
                                <td>{wager.amount}</td>
                                <td>{wager.time}</td> 
                                <td>{calculatePayout(Number(wager.amount), Number(wager.line))}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            {/*
            <div className='vroom-transfer-box'>
                <div className='vroom-input-row'><p className='transfer-box-header'>Burn $VROOM</p></div>
                <div className='vroom-input-row'>
                    <input
                        type="text"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder="Amount"
                    />
                    {!amountValid && <span className='invalid-feedback'>Invalid amount</span>}
                </div>
                <div className='vroom-input-row'>
                  <p style={{color: "red", fontSize: "0.9rem"}}>Please append '.0' to whole number values when entering the amount.</p>
                    <button onClick={handleBurn} disabled={!amountValid}>
                        Burn $VROOM 
                    </button>
                </div>
            </div>*/}
        </div>
    }

    return (
        <div>
            <input
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder="Enter Password"
            />
            <button onClick={checkPassword}>Login</button>
        </div>
    );

}